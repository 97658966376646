var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userdetail container-fluid"},[_c('h1',{staticClass:"page-header primary"},[_vm._v("User Details")]),_c('p',{staticClass:"f3 fw5 tracked"},[_vm._v(_vm._s(_vm.user.Name))]),(
      _vm.getSessionObj.userID === _vm.user.OwnerID ||
        _vm.isAdmin() ||
        _vm.user.ID === _vm.getSessionObj.userID
    )?_c('div',{staticClass:"link blue underline-hover hover-dark-blue",on:{"click":_vm.goToEditAccount}},[_vm._v(" Edit User Account Information ")]):_vm._e(),_c('div',{staticClass:"table-responsive table--no-card m-b-40"},[_c('table',{staticClass:"table table-borderless table-striped"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Name")]),_c('td',{},[_vm._v(_vm._s(_vm.user.Name))])]),_c('tr',[_c('td',[_vm._v("Email")]),_c('td',[_c('a',{staticClass:"gray hover-gold",attrs:{"href":'mailto:' + _vm.user.Email}},[_vm._v(_vm._s(_vm.user.Email))])])]),_c('tr',[_c('td',[_vm._v("Roles")]),_c('td',_vm._l((_vm.user.Roles),function(role){return _c('div',{key:role},[_vm._v(" "+_vm._s(role)+" ")])}),0)]),_c('tr',[_c('td',[_vm._v("ID")]),_c('td',[_vm._v(_vm._s(_vm.user.ID))])]),_c('tr',[_c('td',[_vm._v("Owner Name/ID")]),_c('td',[_c('router-link',{staticClass:"gray hover-gold",attrs:{"to":'/users/' + _vm.user.OwnerID}},[_vm._v(_vm._s(_vm.userOwnerName)+" "),_c('div',{staticClass:"f7"},[_vm._v(_vm._s(_vm.user.OwnerID))])])],1)]),_c('tr',[_c('td',[_vm._v("Created")]),_c('td',[_vm._v(_vm._s(_vm.user.CreatedAt))])]),_c('tr',[_c('td',[_vm._v("Updated")]),_c('td',[_vm._v(_vm._s(_vm.user.UpdatedAt))])])])])]),(
      (_vm.getSessionObj.userID === _vm.user.OwnerID ||
        _vm.isAdmin()) &&
        _vm.user.ID !== _vm.getSessionObj.userID
    )?[_c('hr'),_c('DeleteID',{staticClass:"ph2",attrs:{"data-toggle":"confirmation","urlPath":"/users/","eventToEmit":"user-deleted","routeOnDelete":"/users","ID":_vm.user.ID ? _vm.user.ID : '',"Name":_vm.user.Name ? _vm.user.Name : '',"sessionInfo":_vm.getSessionObj},scopedSlots:_vm._u([{key:"confirmText",fn:function(){return [_c('div',{staticClass:"red f4"},[_vm._v(" ALL projects owned by this user will also be deleted!!! ")]),_c('div',{staticClass:"b"},[_vm._v(" Are you absolutely sure? This can not be undone. ")])]},proxy:true}],null,false,2834394601)},[_c('span',{staticClass:"dib v-mid link blue underline-hover hover-dark-blue pa2"},[_vm._v(" delete user "+_vm._s(_vm.user.Name)+" ")])])]:_vm._e(),(_vm.isAdmin())?[_c('hr'),_c('i',{staticClass:"fa fa-sign-out pa2  gray hover-gold"}),(true)?_c('span',{staticClass:"link blue underline-hover hover-dark-blue",on:{"click":function($event){return _vm.resetTokensConfirmFirst()}}},[_vm._v("log user out of all sessions")]):_vm._e(),_c('b-modal',{ref:"modal",attrs:{"id":"logout-modal"},on:{"ok":_vm.resetTokens},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Invalidate all tokens for user: "),_c('span',{staticClass:"blue"},[_vm._v(" "+_vm._s(_vm.user.Name)+" ")])]},proxy:true}],null,false,762996945)},[_c('span',{},[_vm._v(" This will log the user out of all sesssions: desktop, mobile, and web. ")]),_c('div',{staticClass:"b"},[_vm._v(" Are you sure you want to do this? ")])])]:_vm._e(),_c('p')],2)}
var staticRenderFns = []

export { render, staticRenderFns }