<template>
  <div id="adduser" class="container-fluid">
    <h1 class="page-header primary">Add User</h1>

    <label for="name" class="f6 b mb2 mr2 fl w-10"
      >Username <span class="normal black-60">(required)</span></label
    >
    <input
      class="input-reset ba b--black-20 pa2 mb2 mr2 w-25"
      type="text"
      name="name"
      v-model="input.name"
      placeholder=""
    />

    <p class="pa1"></p>

    <label for="email" class="f6 b mb2 mr2 fl w-10"
      >Email <span class="normal black-60">(required)</span></label
    >
    <input
      class="input-reset ba b--black-20 pa2 mb2 mr2 w-25"
      type="text"
      name="email"
      v-model="input.email"
      placeholder=""
    />

    <p class="pa1"></p>

    <label for="password" class="f6 b mb2 mr2 fl w-10"
      >Password <span class="normal black-60">(required)</span></label
    >
    <input
      id="password"
      class="input-reset ba b--black-20 pa2 mb2 w-25"
      :type="passwordFieldType"
      name="password"
      v-model="input.password"
      required
      autocomplete="password"
      placeholder=""
    />
    <button
      id="eye"
      type="button"
      style="background: url(./font-awesome-svgs/eye.svg); width: 20px; height: 18px; border: 0px; margin-left: 5px;"
      v-on:click="togglePasswordType()"
    />

    <p class="pa1"></p>

    <button
      class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
      type="button"
      v-on:click="addUser()"
    >
      Add User
    </button>
    <router-link to="/users" class="no-underline mh4">
      <button
        class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
        type="button"
      >
        Cancel
      </button>
    </router-link>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'AddUser',
  data() {
    return {
      input: {
        name: '',
        email: '',
        password: '',
      },
      passwordFieldType: 'password',
    };
  },
  computed: mapGetters(['getSessionObj']),
  methods: {
    addUser() {
      if (this.input.name == '') {
        console.log('A username must be present');
        Helpers.showAlert('You must enter a username');
        return;
      }

      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL;
      this.$http
        .post(
          url + '/users',
          [
            {
              Name: this.input.name,
              Email: this.input.email,
              Password: this.input.password,
            },
          ],
          { headers: { authorization: this.getSessionObj.access_token } }
        )
        .then(
          function(response) {
            console.log('Success posting to /users: ', response.body);
            this.$router.push({
              path: '/users',
              query: { alert: 'User ' + response.body[0].Name + ' added' },
            });
          },
          response => {
            // Fail
            console.log('Failed POST to /users', response.body);
            if (response.body) {
              this.alert = 'Error: ' + response.bodyText;
              Helpers.showAlert('Error: ' + response.bodyText);
            } else {
              Helpers.showAlert('Failed to post to /users');
            }
          }
        );
    }, // end addUser
    togglePasswordType() {
      if (this.passwordFieldType === 'password') {
        document.getElementById('eye').style.backgroundImage =
          "url('./font-awesome-svgs/eye-slash.svg')";
      } else {
        document.getElementById('eye').style.backgroundImage =
          "url('./font-awesome-svgs/eye.svg')";
      }
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    }, // end togglePasswordType
  },
  created: function() {
    Helpers.doViewCreation(this);
  },
  components: {},
};
</script>

<style scoped></style>
