<template>
  <span class="DeleteID" @click="confirmDeleteMe">
    <!-- <button class="button" @click="deleteMe">Hello</button> -->
    <!-- <img src="/font-awesome-svgs/trash-alt.svg" width="16" class="svg" @click="deleteMe"> -->
    <!-- <img id="deleteImage" class="svg gray" src="/font-awesome-svgs/trash-alt.svg" @click="deleteMe"/> -->

    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="trash-alt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      class="svg-inline--fa fa-trash-alt fa-w-14 fa-2x"
    >
      <path
        d="M192 188v216c0 6.627-5.373 12-12 12h-24c-6.627 0-12-5.373-12-12V188c0-6.627 5.373-12 12-12h24c6.627 0 12 5.373 12 12zm100-12h-24c-6.627 0-12 5.373-12 12v216c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V188c0-6.627-5.373-12-12-12zm132-96c13.255 0 24 10.745 24 24v12c0 6.627-5.373 12-12 12h-20v336c0 26.51-21.49 48-48 48H80c-26.51 0-48-21.49-48-48V128H12c-6.627 0-12-5.373-12-12v-12c0-13.255 10.745-24 24-24h74.411l34.018-56.696A48 48 0 0 1 173.589 0h100.823a48 48 0 0 1 41.16 23.304L349.589 80H424zm-269.611 0h139.223L276.16 50.913A6 6 0 0 0 271.015 48h-94.028a6 6 0 0 0-5.145 2.913L154.389 80zM368 128H80v330a6 6 0 0 0 6 6h276a6 6 0 0 0 6-6V128z"
        class=""
      ></path>
    </svg>
    <slot> </slot>

    <b-modal id="delete-modal" ref="modal" @ok="deleteMe">
      <template v-slot:modal-title>
        <span class="strong f3">Delete</span
        ><span class="f2 blue"> {{ Name }}</span>
        <br />
        <span class="gray f7">ID: {{ ID }}</span>
      </template>

      <slot name="confirmText">
        <div class="b">
          Are you sure? This action can not be undone.
        </div>
      </slot>
    </b-modal>
  </span>
</template>

<script>
import Helpers from '@/helpers.js';
import { messageBus } from '@/main.js';

export default {
  name: 'DeleteID',
  props: {
    ID: {
      // ID of the object to delete
      type: String,
      required: true,
    },
    Name: {
      // name of thing being deleted
      type: String,
      required: true,
    },
    urlPath: {
      // path for which ID to delete, e.g. /users/ vs. /projects/
      type: String,
      required: true,
    },
    sessionInfo: {
      type: Object,
      required: true,
    },
    eventToEmit: {
      // event name
      type: String,
      required: true,
    },
    routeOnDelete: {
      // where to route the app if delete occurs (can be undefined), e.g. /users
      type: String,
      required: false,
    },
    confirmBeforeDelete: {
      type: Boolean,
      default: true,
      required: false,
    }, // defaults to true, optional parameter.
  },
  data() {
    return {
      showAlert: true,
      alert: 'Default message of delete result',
      deleteConfirmValue: '',
    };
  },
  methods: {
    confirmDeleteMe() {
      this.$bvModal.show('delete-modal');
    },
    deleteMe() {
      // check to make sure props are defined
      if (
        !this.urlPath ||
        !this.ID ||
        !this.sessionInfo ||
        !this.Name ||
        !this.eventToEmit
      ) {
        messageBus.$emit(
          'show-alert',
          'ERROR: Improper prop definition in DeleteID'
        );
        return;
      }

      console.log('DeleteID called (path, ID)', this.urlPath, this.ID);
      if (Helpers.isTokenExpired(this.sessionInfo.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.sessionInfo.apiURL + this.urlPath + this.ID;

      this.$http
        .delete(url, {
          headers: { authorization: this.sessionInfo.access_token },
        })
        .then(
          function(response) {
            console.log('Success deleting: ', response.body);
            //this.$router.push({path: '/users', query: {alert: 'User deleted'}})
            //this.fetchUsers(); // slow way. TODO: just remove from local users []
            this.alert =
              'Success! Deleted ' + this.Name + ' with id ' + this.ID;
            messageBus.$emit(this.eventToEmit, this.alert);
            if (this.routeOnDelete) {
              this.$router.push({
                path: this.routeOnDelete,
                query: { alert: 'Success: ' + this.Name + ' was deleted' },
              });
            }
          },
          response => {
            // Fail
            console.log('Failed DELETE: ', response.body);
            if (response.body) {
              this.alert = 'Error: ' + response.body;
            } else {
              this.alert = 'Failed DELETE';
            }
            messageBus.$emit(this.eventToEmit, this.alert);
          }
        );
    },
  },
  components: {},
  mounted() {
    this.$forceUpdate();
  },
  created() {
    //Helpers.inlineSvgs();
  },
  updated() {}, // end updated()
};
</script>

<style>
.fa-trash-alt {
  width: 15px;
}

.fa-trash-alt path {
  fill: var(--my-link);
}

.fa-trash-alt:hover path {
  fill: var(--my-link-hover-color);
}
</style>
