<template>
  <div class="userdetail container-fluid">
    <h1 class="page-header primary">User Details</h1>
    <p class="f3 fw5 tracked">{{ user.Name }}</p>

    <div
      v-if="
        getSessionObj.userID === user.OwnerID ||
          isAdmin() ||
          user.ID === getSessionObj.userID
      "
      @click="goToEditAccount"
      class="link blue underline-hover hover-dark-blue"
    >
      Edit User Account Information
    </div>

    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td class="">{{ user.Name }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <a class="gray hover-gold" :href="'mailto:' + user.Email">{{
                user.Email
              }}</a>
            </td>
          </tr>
          <tr>
            <td>Roles</td>
            <td>
              <div v-for="role in user.Roles" :key="role">
                {{ role }}
              </div>
            </td>
          </tr>

          <tr>
            <td>ID</td>
            <td>{{ user.ID }}</td>
          </tr>
          <tr>
            <td>Owner Name/ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/users/' + user.OwnerID"
                >{{ userOwnerName }}
                <div class="f7">{{ user.OwnerID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{{ user.CreatedAt }}</td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>{{ user.UpdatedAt }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- if user logged in is the owner of this user, or is an admin, and the user logged in isn't this user, then allowe delete -->
    <template
      v-if="
        (getSessionObj.userID === user.OwnerID ||
          isAdmin()) &&
          user.ID !== getSessionObj.userID
      "
    >
      <hr />

      <DeleteID
        class="ph2"
        data-toggle="confirmation"
        urlPath="/users/"
        eventToEmit="user-deleted"
        routeOnDelete="/users"
        v-bind:ID="user.ID ? user.ID : ''"
        v-bind:Name="user.Name ? user.Name : ''"
        v-bind:sessionInfo="getSessionObj"
      >
        <span class="dib v-mid link blue underline-hover hover-dark-blue pa2">
          delete user {{ user.Name }}
        </span>

        <template v-slot:confirmText>
          <div class="red f4">
            ALL projects owned by this user will also be deleted!!!
          </div>
          <div class="b">
            Are you absolutely sure? This can not be undone.
          </div>
        </template>
      </DeleteID>
    </template>

    <template v-if="isAdmin()">
      <hr />

      <i class="fa fa-sign-out pa2  gray hover-gold" /><span
        v-if="true"
        @click="resetTokensConfirmFirst()"
        class="link blue underline-hover hover-dark-blue"
        >log user out of all sessions</span
      >

      <b-modal id="logout-modal" ref="modal" @ok="resetTokens">
        <template v-slot:modal-title>
          Invalidate all tokens for user:
          <span class="blue">
            {{ user.Name }}
          </span>
        </template>
        <span class="">
          This will log the user out of all sesssions: desktop, mobile, and web.
        </span>
        <div class="b">
          Are you sure you want to do this?
        </div>
      </b-modal>
    </template>

    <p />
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from '@/helpers.js';
import APIhelper from '@/api/apihelper.js';
import DeleteID from '@/components/DeleteID.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserDetail',
  data() {
    return {
      user: '',
      userOwnerName: '',
    };
  },
  computed: mapGetters(['getSessionObj']),
  watch: {
    '$route.params.id'() {
      this.fetchUser(this.$route.params.id);
    }
  },
  methods: {
    fetchUser(id) {
      console.log('fetching user with id: ', id);
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + '/users/' + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success getting /users:' + id, response.bodyText);
            this.user = response.body;
            APIhelper.fetchUser(this.user.OwnerID).then((ret) => {
              console.log('Owner name for user: ', ret);
              this.userOwnerName = ret.Name;
            });
          },
          (response) => {
            // Fail
            console.log('Failed to get /users/:id ', response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /users/:id");
          }
        );
    }, // end fetch projects
    goToEditAccount() {
      // named route
      this.$router.push({
        name: 'updateaccount',
        params: {
          from: 'userdetail',
          id: this.$route.params.id,
          name: this.user.Name,
          email: this.user.Email,
        },
      });
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
    resetTokensConfirmFirst() {
      this.$bvModal.show('logout-modal');
    },
    resetTokens() {
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(
          'Your current session expired BEFORE invaliding tokens. Login and try again.'
        );
        return;
      }

      let url =
        this.getSessionObj.apiURL +
        '/admin' +
        '/users/' +
        this.user.Name +
        '/resetToken';
      this.$http
        .post(
          url,
          {},
          { headers: { authorization: this.getSessionObj.access_token } }
        )
        .then(
          function(response) {
            console.log('Successfully invalidated tokens: ', response.body);

            if (this.user.Name === this.getSessionObj.username) {
              Helpers.logout('Token invalidated. You are now logged out.');
            } else {
              Helpers.showAlert(
                'Success: tokens invalidated for user ' + this.user.Name
              );
            }
          },
          (response) => {
            // Fail
            console.log('Failed POST to /users', response.body);
            if (response.body) {
              this.alert = 'Error: ' + response.bodyText;
              Helpers.showAlert('Error: ' + response.bodyText);
            } else {
              Helpers.showAlert('Failed to post to /users');
            }
          }
        );
    },
  },
  created: function() {
    Helpers.doViewCreation(this);
    this.fetchUser(this.$route.params.id);
  },
  mounted: function() {},
  components: {
    DeleteID,
  },
};
</script>
