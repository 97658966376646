<template>
    <div id="updateaccount" class="container-fluid">
         <!-- Alert message="test" / -->
        <Alert v-if="alert" v-bind:message="alert"></Alert>

        <h1 class="page-header primary">Update Account Information</h1>
        <div class="f4">
            editing user <strong>{{this.$route.params.name}}</strong> with id <strong>{{this.$route.params.id}}</strong>
        </div>

        <p class="pa1"></p>

        <div>NOTE: empty fields will not be changed.</div>

        <p class="pa1"></p>

        <!-- <label for="name" class="f6 b mb2 mr2 fl w-10">Username <span class="normal black-60">(required)</span></label>
        <input class="input-reset ba b--black-20 pa2 mb2 mr2 w-25" type="text" name="name" v-model="input.name" placeholder="" /> -->

        <!-- <p class="pa1"></p> -->

        <label for="email" class="f6 b mb2 mr2 fl w-10">Email <span class="normal black-60">(optional)&nbsp;</span></label>
        <input class="input-reset ba b--black-20 pa2 mb2 mr2 w-25" type="text" name="email" v-model="input.email" placeholder="" />

        <p class="pa1"></p>

        <label for="password" class="f6 b mb2 mr2 fl w-10">Password <span class="normal black-60">(optional)</span></label>
        <input id="password" class="input-reset ba b--black-20 pa2 mb2 w-25" :type="passwordFieldType" name="password" v-model="input.password" required autocomplete="password" placeholder="" />
        <button id="eye" type="button" style="background: url(./font-awesome-svgs/eye.svg); width: 20px; height: 18px; border: 0px; margin-left: 5px;" v-on:click="togglePasswordType()" />


        <p class="pa1"></p>

        <button class="f6 dim primary b bg-white center ph3 pv2 mb2 br3" type="button" v-on:click="updateUser()">Update User</button>
        <router-link to="/myaccount" class="no-underline mh4">
            <button class="f6 dim primary b bg-white center ph3 pv2 mb2 br3" type="button">Cancel</button>
        </router-link>

    </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import Helpers from '@/helpers.js'
import { mapGetters } from 'vuex';

export default {
  name: "UpdateAccount",
  data() {
    return {
      input: {
        name: "",
        email: "",
        password: ""
      },
      alert: "",
      passwordFieldType: "password"
    };
  },
  computed: mapGetters(['getSessionObj']),
  methods: {
    clearAlert() {
      // Child alert will call this function to clear alert when user closes
      // the alert.  If we don't do this, then sending the same alert after
      // the user closes the alert box, it won't show up.
      this.alert = "";
    },
    updateUser() {
      if (
        this.input.password == "" &&
        this.input.email == "" &&
        this.input.name == ""
      ) {
        console.log("You must enter one or more values to change");
        this.alert = "ERROR: All fields are empty";
        return;
      }

      let body = { Name: this.$route.params.name };
      if (this.input.name) {
        body.Name = this.input.name;
      }
      if (this.input.email) {
        body.Email = this.input.email;
      }
      if (this.input.password) {
        body.Password = this.input.password;
      }

      let url = this.getSessionObj.apiURL;
      this.$http
        .put(url + "/users/" + this.$route.params.id, body, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success updating user: ", response.body);
            if (this.$route.params.from == "myaccount") {
              this.$router.push({
                path: "/myaccount",
                query: { alert: "User successfully updated" }
              });
            }
            if (this.$route.params.from == "userdetail") {
              this.$router.push({
                path: "/users/" + this.$route.params.id,
                query: { alert: "User successfully updated" }
              });
            }
          },
          response => {
            // Fail
            console.log("Failed POST to /users/+id", response.bodyText);
            if (response.body) {
              this.alert =
                "Error: " +
                response.bodyText +
                " " +
                response.status +
                " " +
                response.statusText;
            } else {
              Helpers.showAlert("Failed to post to /users/+id");
            }
          }
        );
    }, // end updateUser
    togglePasswordType() {
      if (this.passwordFieldType === "password") {
        document.getElementById("eye").style.backgroundImage =
          "url('./font-awesome-svgs/eye-slash.svg')";
      } else {
        document.getElementById("eye").style.backgroundImage =
          "url('./font-awesome-svgs/eye.svg')";
      }
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    } // end togglePasswordType
  },
  created: function() {
    this.alert = "";
    if (this.$route.query.alert) {
      this.alert = this.$route.query.alert;
    }
    this.input.email = this.$route.params.email;
    this.input.name = this.$route.params.name;
  },
  components: {
    Alert
  }
};
</script>

<style scoped>
</style>
